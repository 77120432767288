import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import axios from '../connect/helpers/Api';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import OrderSection from './order/OrderSection'
import { setDocument } from 'redux/actions/document'
import { setProduct } from 'redux/actions/product'
import ErrorView from 'components/shared/ErrorView'

const propTypes = {
  reference: PropTypes.object,
  document: PropTypes.object,
  setDocument: PropTypes.func,
  product: PropTypes.object,
  setProduct: PropTypes.func
}

const OrderView = ({
  reference,
  document,
  product,
  setDocument,
  setProduct
}) => {
  const { document: documentId, version: versionId } = useParams()
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const { data } = await axios.get(
          `/documents/${documentId}/${versionId}.json`
        )
        setDocument(data)
      } catch (err) {
        setError(true)
      }
    }

    const fetchProduct = async (productId) => {
      try {
        const { data } = await axios.get(
          `/products/${productId}`
        )
        setProduct(data)
      } catch (err) {
        setError(true)
      }
    }
    // #988: when redux-persist doesnt have the reference, user should be redirected to homepage
    if (!reference) {
      history.push('/')
    } else {
      if (!document) {
        fetchDocument()
      }
      if (!product) {
        fetchProduct(reference.product_id)
      }
    }
  }, [
    history,
    reference,
    product,
    document,
    documentId,
    versionId,
    setDocument,
    setProduct
  ])

  const LoadingView = () => (
    <div
      data-testid='LoadingView'
      className='d-flex align-items-center justify-content-center flex-grow-1'
    >
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  )

  const DisplayView = () => {
    if (document && product) {
      return (
        <OrderSection
          document={document}
          product={product}
          versionId={versionId}
          reference={reference}
        />
      )
    } else if (error) {
      return <ErrorView reference={reference} />
    } else {
      return <LoadingView />
    }
  }

  return <DisplayView />
}

OrderView.propTypes = propTypes

const mapStateToProps = ({ reference, document, product }) => ({
  reference,
  document,
  product
})

export default connect(mapStateToProps, { setDocument, setProduct })(OrderView)
